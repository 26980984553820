import React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import Board from '../components/Board';
import PkmnList from '../components/PkmnList';

interface LoaderData {
  activePkmn1: number;
  activePkmn2: number;
  id: number;
  locationPkmn1: number;
  locationPkmn2: number;
  user1: User;
  user2: User;
}

export interface Pkmn {
  id: number;
  level: number;
  species: string;
}

interface User {
  pkmn: Pkmn[];
}

function Battle() {
  const {activePkmn1, activePkmn2, id, locationPkmn1, locationPkmn2, user1, user2} = useLoaderData() as LoaderData;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-3 mb-4">
          <PkmnList activePkmn={activePkmn1} battleID={id} pkmn={user1.pkmn} userIdx={1}/>
        </div>
        <div className="align-items-center col-lg-6 d-flex flex-column mb-5">
          <Board activePkmn1={activePkmn1}
                 activePkmn2={activePkmn2}
                 battleID={id}
                 locationPkmn1={locationPkmn1}
                 locationPkmn2={locationPkmn2}
                 pkmn={[...user1.pkmn, ...user2.pkmn]}/>
          <ul className="list-group list-group-horizontal" style={{width: '375px'}}>
            <li className="list-group-item text-center w-50" style={{borderBottomLeftRadius: 0}}>An item</li>
            <li className="list-group-item text-center w-50" style={{borderBottomRightRadius: 0}}>A second item</li>
          </ul>
          <ul className="list-group list-group-horizontal" style={{width: '375px'}}>
            <li className="list-group-item text-center w-50" style={{borderTopLeftRadius: 0, borderTopWidth: 0}}>A third item</li>
            <li className="list-group-item text-center w-50" style={{borderTopRightRadius: 0, borderTopWidth: 0}}>A fourth item</li>
          </ul>
        </div>
        <div className="col-lg-3 mb-4">
          <PkmnList activePkmn={activePkmn2} battleID={id} pkmn={user2.pkmn} userIdx={2}/>
        </div>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function changeAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const pkmnID = formData.get('pkmnID');
  const userIdx = formData.get('userIdx');

  const url = `${apiBaseURL}/pokemon/battles/${params.battleID}/change/${userIdx}/${pkmnID}`;
  const resp = await fetch(url, {
    method: 'POST',
  });

  if (resp.ok) {
    return redirect('..');
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function loader({ params }: any): Promise<any> {
  const url = `${apiBaseURL}/pokemon/battles/${params.battleID}`
  const resp = await fetch(url);

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export async function moveAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const dst = formData.get('dst');

  const url = `${apiBaseURL}/pokemon/battles/${params.battleID}/move/1/${dst}`;
  const resp = await fetch(url, {
    method: 'POST',
  });

  if (resp.ok) {
    return redirect('..');
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export default Battle;
