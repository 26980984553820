import React from 'react';
import { Link } from 'react-router-dom';
import varokeLogo from '../images/varoke-logo.svg';
import varokeLogoDark from '../images/varoke-logo-dark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faScrewdriverWrench, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import ironIcon from '../images/iron.png';
import goldIcon from '../images/gold.png';
import diamondIcon from '../images/diamond.png';
import panelFeatures from '../images/panel-features.png';
import crossPlatform from '../images/cross-platform.png';
import LandingFooter from './LandingFooter';

interface Props {
  diamond: boolean,
  gold: boolean,
  iron: boolean,
}

function Landing({diamond, gold, iron}: Props) {
  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  const logo = darkMode ? varokeLogoDark : varokeLogo;

  return (
    <>
      <div className="container pt-3" style={{maxWidth: '960px'}}>
        <header>
          <div className="align-items-center border-bottom d-flex mb-4 pb-3">
            <Link to="/">
              <img alt="Varoke Gaming" src={logo} style={{height: '32px'}} />
            </Link>
            <nav className="d-inline-flex ms-auto">
              <Link className="link-body-emphasis py-2 text-decoration-none" to="/login">Sign in</Link>
            </nav>
          </div>
          <div className="mx-auto pt-5 text-center" style={{maxWidth: '700px'}}>
            <h1 className="inter landing-title" style={{letterSpacing: '-1px', lineHeight: 1}}>
              Cloud gaming<br />the way it should be.
            </h1>
            <p className="landing-subtitle text-body-secondary">
              Meet the new generation of Minecraft servers.<br />Fast, simple and managed by experts.
            </p>
          </div>
        </header>
        <div className="mb-5 minecraft-render" style={{
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '265px',
          left: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          position: 'relative',
          right: '50%',
          width: '100vw',
        }}></div>
        <section>
          <div className="mx-auto p-3 pb-md-4 pt-5 text-center" style={{maxWidth: '700px'}}>
            <h2 className="inter" style={{letterSpacing: '-.5px', lineHeight: 1}}>
              Your world, your rules
            </h2>
            <p className="fs-5 text-body-secondary">
              Because building a community is hard enough, we have developed a
              powerful dashboard to customize your Minecraft servers easily.
            </p>
          </div>
          <div className="g-4 py-5 row row-cols-1 row-cols-lg-3">
            <div className="col text-center">
              <div className="align-items-center bg-gradient d-inline-flex fs-2 justify-content-center mb-3 text-bg-primary" style={{borderRadius: '.75rem', height: '4rem', width: '4rem'}}>
                <FontAwesomeIcon icon={faChartLine}/>
              </div>
              <h3 className="fs-3 inter text-body-emphasis">Server analytics</h3>
              <p>Observe how your server performs over time to make better decisions when performing configuration changes.</p>
            </div>
            <div className="col text-center">
              <div className="align-items-center bg-gradient d-inline-flex fs-2 justify-content-center mb-3 text-bg-primary" style={{borderRadius: '.75rem', height: '4rem', width: '4rem'}}>
                <FontAwesomeIcon icon={faScrewdriverWrench}/>
              </div>
              <h3 className="fs-3 inter text-body-emphasis">Plugins</h3>
              <p>Use our intuitive panel to install and configure plugins without editing complex files over FTP connections.</p>
            </div>
            <div className="col text-center">
              <div className="align-items-center bg-gradient d-inline-flex fs-2 justify-content-center mb-3 text-bg-primary" style={{borderRadius: '.75rem', height: '4rem', width: '4rem'}}>
                <FontAwesomeIcon icon={faUsersViewfinder}/>
              </div>
              <h3 className="fs-3 inter text-body-emphasis">User management</h3>
              <p>Get detailed reports about who joined your server to better understand and cultivate your community.</p>
            </div>
          </div>
        </section>
      </div>
      <div className="container-fluid" style={{backgroundColor: 'rgba(0, 0, 0, .025)'}}>
        <section>
          <div className="mx-auto p-3 py-5 text-center" style={{maxWidth: '600px'}}>
            <h2 className="inter" style={{letterSpacing: '-.5px', lineHeight: 1}}>
              Experience the panel in action
            </h2>
            <p className="fs-5 text-body-secondary">
              Our customers love the panel for its exclusive features and
              customizations. Give it a try, stop settling for less.
            </p>
            <Link className="btn btn-primary mt-3 px-5" to="/demo">Try demo panel</Link>
          </div>
        </section>
      </div>
      <div className="container-fluid">
        <section>
          <div className="mx-auto py-5 text-center">
            <h2 className="inter" style={{letterSpacing: '-.5px', lineHeight: 1}}>
              Play the way you want
            </h2>
            <p className="fs-5 text-body-secondary">
              Allow Minecraft Bedrock players to play with Minecraft Java
              players.
            </p>
            <img alt="" src={crossPlatform} style={{maxWidth: '100%', width: '750px'}} />
          </div>
        </section>
      </div>
      <div className="container-fluid" style={{backgroundColor: 'rgba(0, 0, 0, .025)'}}>
        <section>
          <div className="mx-auto pt-5 text-center">
            <h2 className="inter" style={{letterSpacing: '-.5px', lineHeight: 1}}>
              Cloud gaming unlike anything else
            </h2>
            <p className="fs-5 text-body-secondary">
              No need for technical skills. Use our friendly interface to
              customize and monitor your server.
            </p>
            <img alt="" className="w-100" src={panelFeatures} />
          </div>
        </section>
      </div>
      <div className="container" style={{maxWidth: '960px'}}>
        <section>
          <div className="mx-auto p-3 pb-md-4 pt-5 text-center" style={{maxWidth: '700px'}}>
            <h2 className="inter" style={{letterSpacing: '-.5px', lineHeight: 1}}>
              Pricing
            </h2>
            <p className="fs-5 text-body-secondary">
              Our plans have the option of upgrading at any point, only charging you the difference between the upgraded package cost and your current plan.
            </p>
          </div>
          <div className="mb-3 row row-cols-1 row-cols-md-3 text-center">
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="align-items-center card-header d-flex justify-content-center py-3">
                  <img alt="Iron" className="me-2" src={ironIcon} style={{height: '32px'}} />
                  <h4 className="fw-normal my-0">Iron</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title">
                    7.50€<small className="fw-light text-body-secondary">/mo</small>
                  </h1>
                  <ul className="list-unstyled mb-4 mt-3">
                    <li>3 GB of memory</li>
                    <li>2 dedicated CPUs</li>
                    <li>20 GB of SSD storage</li>
                  </ul>
                  <a className={`btn btn-lg btn-primary w-100 ${iron ? '' : 'disabled'}`} href="https://buy.stripe.com/8wM5nL87I5Kt64g28e" role="button">Get started</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="align-items-center card-header d-flex justify-content-center py-3">
                  <img alt="Iron" className="me-2" src={goldIcon} style={{height: '32px'}} />
                  <h4 className="fw-normal my-0">Gold</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title">
                    15€<small className="fw-light text-body-secondary">/mo</small>
                  </h1>
                  <ul className="list-unstyled mb-4 mt-3">
                    <li>6 GB of memory</li>
                    <li>3 dedicated CPUs</li>
                    <li>40 GB of SSD storage</li>
                  </ul>
                  <a className={`btn btn-lg btn-primary w-100 ${gold ? '' : 'disabled'}`} href="https://buy.stripe.com/4gw17vafQ0q9fEQ14b" role="button">Get started</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="align-items-center card-header d-flex justify-content-center py-3">
                  <img alt="Iron" className="me-2" src={diamondIcon} style={{height: '32px'}} />
                  <h4 className="fw-normal my-0">Diamond</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title">
                    22.50€<small className="fw-light text-body-secondary">/mo</small>
                  </h1>
                  <ul className="list-unstyled mb-4 mt-3">
                    <li>9 GB of memory</li>
                    <li>4 dedicated CPUs</li>
                    <li>60 GB of SSD storage</li>
                  </ul>
                  <a className={`btn btn-lg btn-primary w-100 ${diamond ? '' : 'disabled'}`} href="https://buy.stripe.com/dR617v87Ic8R2S414c" role="button">Get started</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LandingFooter/>
      </div>
    </>
  );
}

export default Landing;
