import React from 'react';
import { useSubmit } from 'react-router-dom';
import { Pkmn } from '../routes/Battle';

interface Props {
  activePkmn: number;
  battleID: number;
  pkmn: Pkmn[];
  userIdx: number;
}

function PkmnList({activePkmn, battleID, pkmn, userIdx}: Props) {
  const submit = useSubmit();

  const change = (pkmnID: number) => {
    const formData = new FormData();
    formData.append('pkmnID', pkmnID.toString());
    formData.append('userIdx', userIdx.toString());

    submit(formData, {action: `/pkmn/battles/${battleID}/change`, method: 'post'});
  };

  return (
    <div className="list-group">
      {pkmn.map(pkmn => <button className={`${pkmn.id === activePkmn ? 'active' : ''} align-items-center d-flex list-group-item list-group-item-action`} key={pkmn.id} onClick={_ => change(pkmn.id)} type="button">
        <img alt={pkmn.species} className="me-1" src={`/pkmn/${pkmn.species}.png`} style={{height: '32px', width: '32px'}}/>
        <span>{pkmn.species}</span>
        <div className="flex-grow-1"></div>
        <span className="badge bg-primary rounded-pill">{pkmn.level}</span>
      </button>)}
    </div>
  );
}

export default PkmnList;
