import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, To, useLocation, useNavigate, useParams } from 'react-router-dom';
import varokeLogoDark from '../images/varoke-logo-dark.svg';
import varokeLogo from '../images/varoke-logo.svg';
import { ReactComponent as PersonCircle } from '../images/person-circle.svg';

interface Props {
  darkMode: boolean;
  email: string;
  servers: Server[];
}

interface Server {
  id: string;
  environment: 'prod' | 'test';
  name: string;
  region: 'eu' | 'home' | 'na';
}

function serverName(server: Server) {
  const name = `${server.name}`;

  if (server.environment === 'test') {
    return `${name} #${server.id} (test)`;
  }

  return `${name} #${server.id}`;
}

function serverNameTitle(server: Server) {
  const name = `${server.name}`;

  return <span>{name} <span className="text-secondary">#{server.id}</span></span>;
}

function Navbar1({darkMode, email, servers}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { serverID: currentServerID } = useParams();
  const [show, setShow] = useState(false);

  const currentServer = servers.find(server => server.id === currentServerID)!;

  const serversAtHome = servers.filter(server => server.region === 'home');
  const serversInEurope = servers.filter(server => server.region === 'eu');
  const serversInNorthAmerica = servers.filter(server => server.region === 'na');

  const variant = darkMode ? 'light' : 'dark';
  const logo = darkMode ? varokeLogoDark : varokeLogo;
  const themeText = darkMode ? 'Light mode' : 'Dark mode';

  function toggleTheme() {
    const theme = darkMode ? 'light' : 'dark';
    localStorage.setItem('theme', theme);
    window.location.reload();
  }

  function go(to: To) {
    navigate(to);
    setShow(false);
  }

  return (
    <nav className="bg-body-tertiary navbar navbar-expand-lg pb-lg-0 pt-lg-3">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img alt="logo" className="align-text-top d-inline-block me-3" height="24" src={logo} />
          Varoke Gaming
        </Link>
        <div className="d-none d-lg-block h1 mb-0 navbar-brand text-secondary">/</div>
        <Dropdown className="d-none d-lg-block">
          <Dropdown.Toggle id="dropdown-basic" size="sm" variant={`outline-${variant}`}>
            {serverNameTitle(currentServer)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {serversAtHome.length >= 1 && <Dropdown.Header className="align-items-center d-flex">
                Apple Silicon
                <span className="badge ms-1 text-bg-warning">LAB</span>
            </Dropdown.Header>}
            {serversAtHome.map(server => <Dropdown.Item active={server.id === currentServerID}
                                                        as={Link}
                                                        key={server.id}
                                                        to={location.pathname.replace(currentServerID!, server.id)}>{serverName(server)}</Dropdown.Item>)}
            {serversInEurope.length >= 1 && <Dropdown.Header>Europe</Dropdown.Header>}
            {serversInEurope.map(server => <Dropdown.Item active={server.id === currentServerID}
                                                          as={Link}
                                                          key={server.id}
                                                          to={location.pathname.replace(currentServerID!, server.id)}>{serverName(server)}</Dropdown.Item>)}
            {serversInNorthAmerica.length >= 1 && <Dropdown.Header>North America</Dropdown.Header>}
            {serversInNorthAmerica.map(server => <Dropdown.Item active={server.id === currentServerID}
                                                                as={Link}
                                                                key={server.id}
                                                                to={location.pathname.replace(currentServerID!, server.id)}>{serverName(server)}</Dropdown.Item>)}
            <Dropdown.Divider/>
            <Dropdown.Item href="#">New</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="flex-grow-1"></div>
        <button className="navbar-toggler shadow-none" onClick={() => setShow(!show)} style={{border: 'none'}} type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Varoke Gaming</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <button className="nav-link" onClick={() => go(`/${currentServerID}/overview`)}>Overview</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => go(`/${currentServerID}/analytics`)}>Analytics</button>
              </li>
              <li className="nav-item">
                <a className="disabled nav-link" href="/" style={{color: 'rgba(0, 0, 0, .3)'}}>Chat</a>
              </li>
              <li className="nav-item">
                <a className="disabled nav-link" href="/" style={{color: 'rgba(0, 0, 0, .3)'}}>Logs</a>
              </li>
              <li className="nav-item">
                <a className="disabled nav-link" href="/" style={{color: 'rgba(0, 0, 0, .3)'}}>Operations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={`/${currentServerID}/permissions`}>Permissions</a>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => go(`/${currentServerID}/players`)}>Players</button>
              </li>
              <li className="nav-item">
                <a className="disabled nav-link" href="/" style={{color: 'rgba(0, 0, 0, .3)'}}>Plugins</a>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => go(`/${currentServerID}/settings`)}>Settings</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => go(`/${currentServerID}/whitelist`)}>Whitelist</button>
              </li>
              <hr/>
              <li>
                <button className="nav-link" onClick={toggleTheme}>{themeText}</button>
              </li>
              <li>
                <a className="nav-link" href={`/logout`}>Logout</a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="mb-2 mb-lg-0 me-auto navbar-nav"></ul>
          <div className="dropdown text-end">

          </div>
        </div>

        <Dropdown className="d-none d-lg-block">
          <Dropdown.Toggle as={UserMenuToggle} />
          <Dropdown.Menu>
            <Dropdown.Item href="#">Account</Dropdown.Item>
            <Dropdown.Item href="#" onClick={toggleTheme}>{themeText}</Dropdown.Item>
            <Dropdown.Item as={Link} to="/logout">Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </nav>
  );
}

const UserMenuToggle = React.forwardRef<HTMLAnchorElement>(({children, onClick}: any, ref) => (
  <a href="/" onClick={e => {
    e.preventDefault();
    onClick(e);
  }} ref={ref} aria-expanded="false" className="d-block dropdown-toggle link-body-emphasis text-decoration-none" data-bs-toggle="dropdown">
    <PersonCircle style={{height: '24px', width: '24px'}}/>
  </a>
));

export default Navbar1;
